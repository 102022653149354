/****************************************
*
* Global stylesheet
*
*****************************************/

.fixed-width-content {
  width:1200px;
  padding: 0 15px;
  margin: 0 auto;
}

.flexible-content {
  margin: 0 50px;
}

.button-wrapper {
  margin:0 50px;
}

.youtube-vid {
  width: 100%;
  height:100%;
  //object-fit: cover;
}

.filtered-image {

  img {
    filter: grayscale(1) opacity(0.37);
  }
}

body {

  .page-wrapper {
    position: relative;
  }

  section {
    overflow: hidden;
  }
}

header {

  &.page-header {
    position: relative;
    background-color: $brandBlue;
    height: 134px;

    .fostering-logo {
      position: absolute;
      left:48px;
      top:34px;
      display: block;
      height:64px;
      width: 261px;
      background: transparent url("#{$pathToImages}/fostering-logo.png") 0 0 no-repeat;
      background-size: 261px 64px;
      text-indent: -10000px;
    }

    #search-trigger {
      display: none;

      button {
        border: none;
        color: $white;
        background: transparent url("#{$pathToImages}/magnifing-glass.png") 5px 5px no-repeat;
        background-size: 25px 25px;
        display: block;
        width: 30px;
        height:30px;
        text-indent: -10000px;
        cursor: pointer;
      }
    }

    #search-wrapper {

      &.on {
        display: inline-block;
        position: absolute;
        right:50px;
        top: 75px;
      }
    }

    .page-search-wrapper {
      display: inline-block;
      position: absolute;
      right:26px;
      top: 20px;
      height: 50px;

      .page-search {
        //margin:44px 0 0;
        margin-left: auto;
        display: inline-block;

        .page-search-form {
          @include flex-container(row, flex-start);
          @include border-radius(6px);
          border: 2px solid $white;

          .form-item {

            label {
              display: none;
            }

            input {
              display: inline-block;
              border:none;
              background: transparent;
              color: white;
              height: 35px;
              min-width: 180px;
              @include font-size(18);
              font-weight: $regular;
              padding:0 10px;
              outline: none;

              &::placeholder {
                color: $white;
                text-align: center;
              }
            }
          }

          .form-actions {

            input[type=submit] {
              border: none;
              color: $white;
              background: transparent url("#{$pathToImages}/magnifing-glass.png") 50% 50% no-repeat;
              background-size: 20px 20px;
              display: block;
              width: 65px;
              height:35px;
              text-indent: -10000px;
              cursor: pointer;

            }
          }
        }
      }
    }

    .top-nav {
      position: inherit;
      top:80px;
      margin: 0 auto;
      //max-width: 1200px;

      nav {
        position: relative;
        z-index: 2;

        &.navbar {

          ul {
            margin: 0 26px 0 auto;

            li {
              position: relative;

              &:not(:nth-child(1)) {

                &:before {
                  position: absolute;
                  left:0;
                  top:5px;
                  content: '';
                  display: block;
                  height:20px;
                  width: 1px;
                  background-color: $white;

                }
              }

              a {
                padding:0 18px;
                font-family: $Montserrat-font;
                @include font-size(20);
                font-weight: $regular;
                color: $white;

                &:hover {
                  color: $white;
                }
              }

              &:nth-child(1) {

                a {
                  padding-left:0;
                }
              }

              &:last-child {

                a {
                  padding-right:0;
                }
              }
            }
          }
        }

        &.half-right {
          margin-left: 37%;

        }

        .burger-menu {
          position: absolute;
          right:30px;
          top:10px;
          width:44px;
          height:34px;
          border:1px solid $white;
          border-radius: 4px;
          background-color: transparent;
          background-image: none;
          outline: none;

          .icon-bar {
            display: block;
            width: 26px;
            height: 2px;
            border-radius: 1px;
            background-color: #fff;

            &:not(:last-child ) {
              margin-bottom: 5px;
            }
          }
        }
      }
    }



    /*.hero-section {
      position: absolute;
      top: 0;
      z-index: 1;
      width:100%;
      height: 100%;
      overflow: hidden;

      .hero-image-wrapper {
        height: 100%;
        overflow: hidden;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .hero-content {
        height: 100%;
        width:100%;

        h2 {
          position: inherit;
          margin: 0;
          @include centerH;
          top: 112px;
          text-indent: -10000px;
          width:512px;
          height:157px;
          background: transparent url("#{$pathToImages}/fostering-logo-x2.png") 0 0 no-repeat;
          background-size: 512px 157px;
        }

        h3 {
          position: inherit;
          width:100%;
          text-align: center;
          @include font-size(25);
          font-family: $Montserrat-font;
          font-weight: $regular;
          color: $white;
          top:264px;
        }

        .brand-btn {
          position: inherit;
          @include centerH;
          top:374px;
        }
      }
    }*/
  }
}

.hero {
  position: relative;
  overflow: hidden;

  .hero-img {
    width: 100%;
    object-fit: cover;
    filter: brightness(0.70);
  }

  .hero-content {
    position: absolute;
    left:36px;
    top:36px;
    padding: 0 48px;
    max-width: 1200px;
    max-height: 280px;
    color: $brandDkBlue;
    background-color: rgba(255,255,255,0.9);
    @include border-radius(10px);
    width: 44%;
    height: 100%;
    @include box-shadow(5px, 5px, 15px, rgba(20,20,20,0.5));

    h1 {
      width: calc(100% - 60px);
      color: $brandBlue;
      text-align: left;
      margin: 30px 0 30px 0;

      .subhead {
        margin-top: -3px;
        display: block;
        @include font-size(30);
      }
    }

    .find-out-more {

    }
  }
}

.subpage-hero {
  position: relative;
  overflow: hidden;
  max-height: 200px;

  .hero-img {
    width: 100%;
    object-fit: cover;
    filter: brightness(0.70);
  }

  h1 {
    @include centerer;
    margin: 0;
    @include font-size(36);
    font-weight: $semiBold;
    color: $white;
  }
}

.intro-section {

  .intro-section-content {
    @include flex-container(row, space-between);
    margin-bottom: 80px;

    & > div {
      width:calc(50% - 15px);

      & > div {

        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }

    .actions {
      position: relative;
      height: 100px;
      overflow: hidden;

      button {
        @include centerer;
      }
    }

    .image-cell {

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .video-cell  {
      position: relative;
      padding-bottom: 56.25%;
      //padding-top: 30px;
      height: 0;
      overflow: hidden!important;
    }

    .video-cell  iframe,
    .video-cell  object,
    .video-cell  embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.quote-on-image-section {
  position: relative;
  height: 347px;

  & > img {
    position: absolute;
    top:0;
    left: 0;
    width:100%;
    height: 100%;
    object-fit: cover;
  }

  .quote-on-image-section-content {
    position: relative;
    z-index: 2;
    padding: 60px;

    .quote {
      color: $white;
      padding: 0 30px;

      p {
        @include font-size(34);
        font-weight: $light;
        display: inline-block;

        span {
          position: relative;
          padding: 0 35px;

          &:before {
            content: '';
            position: absolute;
            top:-5px;
            left:0;
            width: 27px;
            height: 22px;
            background: transparent url('#{$pathToImages}/before-quotes.png') 0 0 no-repeat;
            background-size: 27px 22px;
          }

          &:after {
            content: '';
            position: absolute;
            bottom: 22px;
            right: 0;
            width: 27px;
            height: 22px;
            background: transparent url('#{$pathToImages}/after-quotes.png') 0 0 no-repeat;
            background-size: 27px 22px;
          }
        }

        &.credit {
          @include font-size(18);
          font-style: italic;
        }
      }
    }
  }
}

.info-on-image-section {
  position: relative;
  height: 800px;

  & > img {
    position: absolute;
    top:0;
    left: 0;
    width:100%;
    height: 100%;
    object-fit: cover;
  }

  h2 {
    position: relative;
    z-index: 10;
    color: $white;
  }

  .info-on-image-section-content {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 3rem;

    .quote {
      grid-column: 1/2;
      grid-row: 1/2;
      color: $white;
      padding: 0 30px;

      p {
        @include font-size(45);
        display: inline-block;

        span {
          position: relative;
          padding: 0 35px;

          &:before {
            content: '';
            position: absolute;
            top:-5px;
            left:0;
            width: 27px;
            height: 22px;
            background: transparent url('#{$pathToImages}/before-quotes.png') 0 0 no-repeat;
            background-size: 27px 22px;
          }

          &:after {
            content: '';
            position: absolute;
            bottom: 22px;
            right: 0;
            width: 27px;
            height: 22px;
            background: transparent url('#{$pathToImages}/after-quotes.png') 0 0 no-repeat;
            background-size: 27px 22px;
          }
        }

        &.credit {
          @include font-size(18);
          font-style: italic;
        }
      }
    }

    .list {
      grid-column: 2/3;
      grid-row: 1/2;

      ol {
        list-style: none;
        padding-left:60px;
        counter-reset: list-counter;

        li {
          position: relative;
          @include font-size(20);
          font-weight: $regular;
          margin-bottom: 25px;
          color: $white;

          &:before {
            content: counter(list-counter);
            counter-increment: list-counter;
            position: absolute;
            top: 5px;
            left:-60px;
            display: inline-block;
            height: 32px;
            width: 32px;
            border:2px solid $white;
            @include border-radius(16px);
            color: $white;
            @include font-size(18);
            font-weight: $medium;
            text-align: center;
            line-height: 30px;
          }
        }
      }
    }
  }
}

.case-studies {
  min-height: 630px;
  background-color: $caseStudiesBg;

  h2 {
    color: $brandBlue;
  }

  .case-studies-content {

    .flexslider {

      .slides {
        margin: 0 40px;

        .slide {

          .slide-content {
            width:100%;
            display: grid;
            grid-template-columns: auto 370px;
            //grid-template-rows: 100%;
            grid-template-rows: 1fr;
            grid-gap: 30px;

            .case-study-content {
              grid-column: 1/2;
              grid-row: 1/2;

              h3 {
                @include font-size(20);
                font-weight: $semiBold;
                margin-bottom: -15px;
              }

              p {
                @include font-size(20);
                font-weight: $regular;
                line-height: 28px;
                margin-bottom: 29px;
              }
            }

            .case-study-image {
              grid-column: 2/3;
              grid-row: 1/2;

              img {
                width: 100%;
                max-width: 500px;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
}

.the-process {
  min-height: 800px;

  h2 {
    color: $brandBlue;
  }

  .the-process-content {

    .process-intro {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & > div {
        width: 50%;
      }

      .intro-text {
        margin-right: 30px;

        p {
          @include font-size(20);
          font-weight: $regular;
        }
      }

      .ledgend {
        position: relative;
        top: -10px;
        margin-left: 30px;

        p {
          height: 50px;
          padding-left: 75px;
          margin-bottom: 38px;
          @include font-size(20);
          line-height: 50px;
          font-weight: $semiBold;

          &.pre-reveiw {
            color: $brandRed;
            background: transparent url("#{$pathToImages}/red-clock.png") 0 0 no-repeat;
            background-size: 50px 50px;
          }

          &.post-reveiw {
            color: $brandBlue;
            background: transparent url("#{$pathToImages}/blue-clock.png") 0 0 no-repeat;
            background-size: 50px 50px;
          }
        }
      }
    }

    .the-process-grid {
      margin: 80px 0;
      display: grid;
      grid-template-columns: 44vw 6vw 44vw;
      grid-template-rows: 35vw 5.833vw 35vw 5.833vw 35vw;
      grid-template-areas:
          "step1 ar-1-2 step2"
          ". . ar-2-3"
          "step4 ar-3-4 step3"
          "ar-4-5 . ."
          "step5 ar-5-6 step6";

      & > div {
        position: relative;
        border:3px solid $brandBlue;
        @include border-radius(20px);
        color: $white;
        text-align: center;
        transition: all 0.5s ease-in-out;

        h3 {
          color: $brandBlue;
        }

        & > .step-text {
          overflow: hidden;
          height: 0;
        }
        
        & > .step-img {
          position: absolute;
          @include centerer;
        }

        &:hover {
          color: $white;
          background-color: $brandBlue;

          h3 {
            color: $white;
          }

          & > .step-text {
            height:auto;
          }

          & > .step-img {
            opacity: 0;
          }

          &.arrow {
            background-color: $white;
          }
        }

        .step-text {
          padding: 0 12px;
        }

        p {
          @include font-size(22);
          margin: 40px 0 0;

          &.step-text {
            @include font-size(20);
          }
        }

        h3 {
          @include font-size(28);
          line-height: 1;
          margin-top: 0;
        }

        &.arrow {
          position: relative;
          border: none;

          span {
            display: block;
            @include centerer;
          }

          &.vert {
            
            &.red-right {

              span {
                background: transparent url("#{$pathToImages}/red-arrow-right-rc.png") 0 0 no-repeat;
                background-size: 28px 49px;
              }
            }

            &.red-left {

              span {
                background: transparent url("#{$pathToImages}/red-arrow-left-rc.png") 0 0 no-repeat;
                background-size: 28px 49px;
              }
            }

            &.blue-left {

              span {
                background: transparent url("#{$pathToImages}/blue-arrow-left-rc.png") 0 0 no-repeat;
                background-size: 28px 49px;
              }
            }

            &.blue-right {

              span {
                background: transparent url("#{$pathToImages}/blue-arrow-right-rc.png") 0 0 no-repeat;
                background-size: 28px 49px;
              }
            }

            span {
              width:28px;
              height: 49px;
            }
          }

          &.horiz {

            &.red-down {

              span {
                background: transparent url("#{$pathToImages}/red-arrow-down-rc.png") 0 0 no-repeat;
                background-size: 49px 28px;
              }
            }

            &.blue-down {

              span {
                background: transparent url("#{$pathToImages}/blue-arrow-down-rc.png") 0 0 no-repeat;
                background-size: 49px 28px;
              }
            }

            span {
              width:49px;
              height: 28px;
            }
          }
        }

        &.pre-reveiw {
          border-color: $brandRed;
          color: $white;

          h3 {
            color: $brandRed;
          }

          & > .step-text {
            overflow: hidden;
            height: 0;
          }

          &:hover {
            color: $white;
            background-color: $brandRed;

            h3 {
              color: $white;
            }

            & > .step-text {
              height:auto;
            }

            & > .step-img {
              opacity: 0;
            }
          }
        }
      }

      .step1 {
        grid-area: step1;
        
        .step-img {
          width:149px;
          height: 112px;
          background: transparent url("#{$pathToImages}/step-1-bg-298x223.png") 0 0 no-repeat;
          background-size: 149px 112px;
          top:59%;
        }
      }

      .ar-1-2 {
        grid-area: ar-1-2;
      }

      .step2 {
        grid-area: step2;

        .step-img {
          width:98px;
          height: 89px;
          background: transparent url("#{$pathToImages}/step-2-bg-195x177.png") 0 0 no-repeat;
          background-size: 98px 89px;
          top:59%;
        }
      }

      .ar-2-3 {
        grid-area: ar-2-3;
      }

      .step3 {
        grid-area: step3;

        .step-img {
          width:130px;
          height: 103px;
          background: transparent url("#{$pathToImages}/step-3-bg-260x206.png") 0 0 no-repeat;
          background-size: 130px 103px;
          top:59%;
        }
      }

      .ar-3-4 {
        grid-area: ar-3-4;
      }

      .step4 {
        grid-area: step4;

        .step-img {
          width:102px;
          height: 115px;
          background: transparent url("#{$pathToImages}/step-4-bg-204x230.png") 0 0 no-repeat;
          background-size: 102px 115px;
          top:59%;
        }
      }

      .ar-4-5 {
        grid-area: ar-4-5;
      }

      .step5 {
        grid-area: step5;

        .step-img {
          width:110px;
          height: 115px;
          background: transparent url("#{$pathToImages}/step-5-bg-220x230.png") 0 0 no-repeat;
          background-size: 110px 115px;
          top:59%;
        }
      }

      .ar-5-6 {
        grid-area: ar-5-6;
      }

      .step6 {
        grid-area: step6;

        .step-img {
          width:173px;
          height: 173px;
          background: transparent url("#{$pathToImages}/step-6-bg-345x345.png") 0 0 no-repeat;
          background-size: 173px 173px;
          top:59%;
        }
      }
    }

    .process-action {
      margin: 80px 0;
      text-align: center;

      button, a {

        &.btn {

          &.wide-btn {
            padding: 0 64px;
          }
        }
      }
    }
  }

}

.content {

  & + .content {
    margin-top: -20px;

    div {

      &:nth-child(1) {

        .wysiwyg {
          margin-top: 0;
        }
      }
    }
  }

  .wysiwyg {

    ol,
    ul {

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      li {
        margin: 0;
        line-height: 28px;
      }
    }
  }

  .special-image-block {
    padding: 0 36px;
    width: 100%;

    img {
      width: 100%;
      margin:0;
    }
  }

  .video-container {
    padding: 0 36px;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin: 0 36px 0 36px;
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & + .heading-row {
    margin-top: -40px;
  }
}

.one-col-content {

  .content-row {
    margin: 50px;

    .wysiwyg {

      h2 {
        text-align: left;
        margin: 30px 0;
      }
    }
  }
}

.two-col-content {

  .content-row {
    @include flex-container(row, space-between);


    & > div {
      width:50%;

      &:nth-child(2) {
        padding-left: 3vw;
      }
    }

    .wysiwyg {

      h2 {
        text-align: left;
        margin: 10px 0;

        &:nth-child(1) {
          //margin-top: -42px;
        }
      }

      p {
        padding-right: 0;
      }
    }

    .content-col {

      .inner {
        //padding:0 36px;

        &.info-panel {
          margin-bottom: 60px;

          p {
            margin-bottom: 30px;
            @include font-size(20);
            font-weight: $regular;
          }

          h4 {

            &.sub-head {
              @include font-size(20);
              font-weight: $bold;
              margin-bottom: 5px;
            }
          }

          img {
            width:100%;

            &+ p {
              margin-top: 30px;
            }
          }
        }

        a {

          h3 {
            margin:0;
          }
        }
      }

    }
  }
}

.two-col {
  margin: 50px 0;

  .content-row {
    @include flex-container(row, flex-start);
    margin:0 50px;
    flex-wrap: wrap;

    .content-col {
      position: relative;
      width: 50%;
      margin-bottom: 40px;

      &.image-block {

        img {
          display: block;
          width: 100%;
        }
      }

      &.video-block {
        position: relative;
        padding-bottom: 30.89%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
      }

      &.video-block iframe,
      &.video-block object,
      &.video-block embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &.text-block {

        h2 {
          text-align: left;
        }

        &:nth-child(2) {

          padding-left: 3vw;
        }
      }
    }

    & + .button-wrapper {
      margin-top: -40px;
    }
  }
}

.events-section {

  .events-wrapper {
    @include flex-container(row, space-between);
    flex-wrap: wrap;
    width: 93.75vw;
    margin: 0 auto;

    .event-block {

      &.redBg,
      &.dkRedBg,
      &.blueBg,
      &.dkBlueBg {

        h3, p {
          color: $white;
        }
      }

      &.redBg {

        a {

          &:hover {
            border-color: transparent;
            color: $brandRed;
          }
        }
      }
      &.dkRedBg {

        a {

          &:hover {
            border-color: transparent;
            color: $brandDkRed;
          }
        }
      }
      &.blueBg {

        a {

          &:hover {
            border-color: transparent;
            color: $brandBlue;
          }
        }
      }
      &.dkBlueBg {

        a {

          &:hover {
            border-color: transparent;
            color: $brandDkBlue;
          }
        }
      }
    }

    .event-block {
      position: relative;
      padding: 36px 36px 70px;
      width: 31.62%;
      margin-bottom: 30px;
      @include border-radius(10px);
      overflow: hidden;

      .event-content {

        &.wysiwyg {
          padding: 15px 0;
        }
      }

      img {
        width: 100%;
      }

      h3, p {
        padding: 0 15px;
      }

      h3 {
        @include font-size(20);
        margin-bottom: 20px;
        color: $brandDkBlue;
        font-weight: $semiBold;
        line-height: 28px;

        & + p {
          @include font-size(20);
          font-weight: $regular;
        }
      }

      p {
        @include font-size(20);
        font-weight: $regular;

        &.static-copy {
          position: absolute;
          left: 51px;
          bottom: 36px;
          font-weight: $semiBold;
        }
      }

      a {
        position: absolute;
        left: 51px;
        bottom: 36px;
      }
    }
  }
}

.circular-cta-section {
  margin-top: 60px;

  .circular-cta-wrapper {
    @include flex-container(row, space-between);
    flex-wrap: wrap;
    width: 93.75vw;
    margin: 0 auto;

    .circular-cta {
      position: relative;
      padding: 36px 36px 70px;
      width: 29.666vw;
      height: 29.666vw;
      margin-bottom: 30px;
      @include border-radius(14.833vw);
      overflow: hidden;

      a {
        display: block;
        @include fill-parent;
        @include border-radius(14.833vw);
        color: $white;text-decoration: none;
        overflow: hidden;
        transition: all 0.5s ease-in-out;

        .cta-content {
          height: 100%;
          text-align: left;
          padding: 6.25vw 3vw 6.25vw 7.166vw;

          p {
            //@include font-size(36);
            font-size: 3vw;
            font-weight: $light;
            line-height: 3.416vw;
          }

          h3 {
            //@include font-size(67);
            font-size: 5.583vw;
            font-weight: $regular;
            line-height: 4.166vw;
          }
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

.dynamic-blocks {

  .dynamic-block-content {
    @include flex-container(row, flex-start);
    flex-wrap: wrap;

    .dynamic-block {
      width: 33.33%;
      position: relative;

      .aspect-box {
        padding-top: 87%;
        position: relative;
        background-color: inherit;
      }

      &.image-block {

        img {
          display: block;
          width: 100%;
          object-fit: cover;
        }
      }

      &.link-block {

        a {
          display: block;
          height: 100%;
          background-color: inherit;

          div {
            @include fill-parent;
            background-color: inherit;
            color: $white;

            &.content {

              h2 {
                @include centerer;
                @include font-size(28);
                margin: 0;
                opacity: 1;
                font-weight: $light;
                color: $white;
              }

              p {
                @include centerer;
                @include font-size(20);
                width:100%;
                padding:20% 18%;
                margin: 0;
                font-weight: $light;
                color: $white;
                opacity: 0;
                transition: opacity 0.5s ease-in-out;
                background-color: inherit;
              }
            }
          }

          &:hover {

            div {

              &.content {

                h2 {
                  opacity: 0;
                }

                p {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}

.contact-form-section {
  
  .contact-form-content {
    width: 100%;
    max-width: 1200px;
    padding: 0 36px 2rem;

    .contact-form-heading {
      color: $white;
      margin: 30px 0 20px;
    }

    .contact-form-intro {

      & > div {

        &.wysiwyg {

          p {
            color: $white;
          }
        }
      }
    }

    .contact-form-wrapper {
      position: relative;

      .contact-form {
        & > div {
          //border:1px solid gold;

          &.form-item {
            min-height: 70px;

            p.label,
            label {
              color: $white;
              height: 50px;
              line-height: 50px;
              @include font-size(20);
              font-weight: $light;
              vertical-align: top;
            }

            input {
              height: 50px;
              padding: 0 10px;
              background-color: transparent;
              border: 2px solid $white;
              @include border-radius(6px);
              color: $white;
              line-height: 50px;
              @include font-size(20);
              font-weight: $light;
              width: 100%;

              &::placeholder {
                color: $white;
                @include font-size(20);
                font-weight: $light;
              }

              &[type=checkbox] {
                display: inline;
                height: 50px;
                width:20px;
                margin-left: 20px;
              }
            }

            textarea { 
              height:120px;
              padding: 10px;
              margin-bottom: 12px;
              background-color: transparent;
              border: 2px solid $white;
              @include border-radius(6px);
              color: $white;
              width: 100%;

              &::placeholder {
                color: $white;
                @include font-size(20);
                font-weight: $light;
              }
            }

            &.faux-checkbox {

              input[type="checkbox"] {
                display: none;

                & + label {
                  width: 90%;
                  position: relative;
                  text-align: left;

                  .sudo-checkbox {
                    position: absolute;
                    right: 0;
                    display: inline-block;
                    border:2px solid $white;
                    border-radius: 6px;
                    width: 50px;
                    height:50px;
                    top:0;
                    background: transparent;
                    transition: all 0.5s;
                    text-align: center;
                    line-height: 44px;

                    i {
                      display: none;
                      @include font-size(15);
                    }
                  }
                }

                &:checked {

                  & + label {

                    .sudo-radio,
                    .sudo-checkbox {
                      border-color: $white;

                      i {
                        display: block;
                        line-height: 44px;
                        text-align: center;
                        color: $white;
                        font-size: 1.5em;
                      }
                    }
                  }
                }
              }
            }

            &.faux-radio {
              @include flex-container(row, space-between);
              min-height: unset !important; 
              p {

                &.label {
                  padding-left: 58px;
                }
              }

              .opt-group {
                @include flex-container(row, space-between);

                .radio {

                  &:last-child {
                    margin-left: 20px;
                  }

                  input[type=radio] {
                    display: none;

                    & + label {

                      span {
                        display: inline-block;
                        height: 50px;
                        vertical-align: middle;
                      }

                      .sudo-radio {
                        display: inline-block;
                        width: 50px;
                        height: 50px;
                        border:2px solid $white;
                        border-radius: 25px;
                        margin-left:10px;

                        i {
                          display: none;
                        }
                      }
                    }

                    &:checked {

                      & + label {

                        span {
                          vertical-align: middle;
                        }

                        .sudo-radio {

                          i {
                            display: block;
                            line-height: 44px;
                            text-align: center;
                            color: $white;
                            font-size: 1.5em;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &.form-actions {
            padding-left: 0;

            input[type=submit] {
              height: 50px;
              width: 200px;
              margin-top: 2px;
              padding: 0 10px;
              background-color: $white;
              border: 2px solid $white;
              @include border-radius(6px);
              color: $brandPrimary;
              line-height: 50px;
              @include font-size(20);
              font-weight: $light;
              outline: none;
              transition: all 0.5s ease-in-out;

              &:hover {
                background-color: transparent;
                color: $white;
              }
            }
          }
        }
      }

      .privacy-policy {
        right: 0;
        bottom: 26px;
        color: $white;
        @include font-size(15);
        font-weight: $light;
        margin: 2rem 0;

        a {
          display: inline;
          color: $white;
          text-decoration: underline;
        }
      }
    }
  }
}

.search-page-results {

  & + .actions {
    margin-top:-20px;
  }

  .search-page-results-content {
    padding:50px;

    h2 {
      text-align: center;
      color: $brandSecondary;
      font-family: $Montserrat-font;
      margin-bottom: 40px;
    }

    ol {
      list-style: none;
      padding-left:60px;
      counter-reset: list-counter;

      li {
        position: relative;
        @include font-size(18);
        font-weight: $light;
        margin-bottom: 10px;
        border-bottom: 1px solid $mid-grey;

        &:before {
          content: counter(list-counter);
          counter-increment: list-counter;
          position: absolute;
          top: 5px;
          left:-60px;
          display: inline-block;
          height: 36px;
          width: 36px;
          color: $white;
          background-color: $brandPrimary;
          font-family: $Montserrat-font;
          @include font-size(24);
          font-weight: $regular;
          text-align: center;
          line-height: 36px;
        }

        h3 {
          text-align: left;
          font-family: $Montserrat-font;
          font-weight: $regular;
          @include font-size(36);
          line-height: 46px;

          a {
            color: $brandPrimary;

            &:hover {
              color: $brandTertiary;
              text-decoration: none;
            }
          }
        }

        p {
          font-family: "Montserrat", sans-serif;
          @include font-size(18);
          font-weight: $light;

          strong {
            color: $brandPrimary;
            font-weight: $semiBold;
          }

          span {
            color: $brandPrimary;
            font-weight: $regular;
          }
        }

      }
    }

    .pager {
      padding-left: 0;
      margin: 20px 0;
      list-style: none;
      text-align: center;
      @include clearfix;

      li {
        display: inline;
        > a,
        > span {
          display: inline-block;
          padding: 5px 14px;
          color: $brandSecondary;
          background-color: $pager-bg;
          border: 1px solid $pager-border;
          @include border-radius(15px);
        }

        > a:hover,
        > a:focus {
          text-decoration: none;
          background-color: $pager-hover-bg;
        }
      }

      .next {
        > a,
        > span {
          float: right;
        }
      }

      .previous {
        > a,
        > span {
          float: left;
        }
      }

      .disabled {
        > a,
        > a:hover,
        > a:focus,
        > span {
          color: $pager-disabled-color;
          background-color: $pager-bg;
          cursor: not-allowed;
        }
      }
    }
  }
}


footer {
  margin-top: 30px;
  min-height:240px;
  background-color: $footerBg;
  color:$white;

  .sticky-footer {

    &.fixed {
      position: fixed;
      width:100%;
      bottom: 0;
      z-index: 100;
      background-color: $grey;
    }
  }

  .footer-top-row {
    height: 65px;
    background-color: $grey;

    .content {
      @include flex-container(row, space-between);
      max-height: 65px;
      padding:16px 32px;

      & > div {
        width: 50%;
      }

      .social {
        grid-area: social;
        width: auto;
        margin-left: 15px;
        @include flex-container();
        justify-content: flex-start;

        a {
          display: inline-block;
          color: $white;
          margin-right: 16px;

          .fa {
            @include font-size(30);
          }

          &:nth-child(4) {
            margin-right: 0;
          }
        }

        p {
          color: $white;
          text-align: right;
          @include font-size(18);

          a {
            text-decoration: underline;
            margin: 0;
          }
        }
      }

      .sign-up {

        .inner {
          text-align: left;
        }

        a {

          &.sign-up {
            @include font-size(18);
            margin: 0 30px 0 0;
            text-align: right;
            position: relative;
            top: -5px;
          }
        }
      }
    }
  }

  .footer-grid {

    &.footer-content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 100px;
      grid-template-areas:
              "footer-nav copyright logos";
      height: inherit;
      margin-top: 60px;
    }
  }

  .cell {
    position: relative;
  }



  .footer-nav {
    grid-area: footer-nav;
    nav {

      ul {
        margin: 43px 0 0 15px;
        @include flex-container(row, flex-start);
        list-style: none;
        padding: 0;

        li {
          padding:0 10px;

          &:nth-child(1){
            padding-left: 0;
          }

          &:not(:last-child) {
            border-right: 1px solid;
          }

          a {
            @include font-size(16);
            font-weight: $light;
            color: $white;
          }
        }
      }
    }
  }

  .copyright {
    grid-area: copyright;
    @include font-size(16);
    font-weight: $light;
    text-align: center;

    p {
      margin-top: 43px;
    }
  }

  .logos {
    grid-area: logos;
    @include flex-container();
    justify-content: flex-end;

    .wf-logo {
      position: absolute;
      bottom: 40px;
      right: 30px;
      display: block;
      width: 103px;
      height: 57px;
      background: transparent url("#{$pathToImages}/waltham-forest-logo.png");
      background-size: 103px 57px;
    }

  }
}






