$adminPrimary: $brandBlue;
$adminSecondary: $brandDkBlue;

.subpage {

  &.path-user {

    .login-form {
      width: 1200px;
      margin: 0 auto;
    }

    form {

      &.user-login-form {
        @include flex-container(column, space-around);
        align-items: center;
        min-height: 500px;
        padding: 50px;

        .form-item,
        .form-actions {
          width:80%;
          @include flex-container(column, space-between);

          input[type=text],
          input[type=password] {
            width:60%;
            height: 54px;
            padding:0 20px;
            background-color: transparent;
            color: $text-color;
            outline: none;
            @include font-size(20);
            line-height: 54px;
            @include border-radius(6px);
            border:2px solid $adminSecondary;
          }

          input[type=submit] {
            width: 33%;
            padding: 0 30px;
            border-color: $adminPrimary;
            background-color: $adminPrimary;
            @include font-size(20);
            line-height: 54px;
            @include border-radius(6px);
            font-weight: $regular;
            color: $white;
            transition: all 0.5s ease-in-out;

            &:hover {
              background-color: $adminSecondary;
              border-color: $adminSecondary;
              border:2px solid $adminSecondary;
            }
          }

          label {
            @include font-size(20);
            color: $text-color;
          }
        }
      }
    }
  }
}


.block-local-tasks-block {

  ul {
    border-bottom: 1px solid #004A97;
    border-collapse: collapse;
    height: 18px;
    line-height: normal;
    list-style: none outside none;
    margin: 5px 0 5px 0;
    padding: 0;
    white-space: nowrap;

    li {
      display: inline;
      background:none;
      padding:0;
      float:left;

      a {
        background-color:#004A97;
        background: -moz-linear-gradient(center top , rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.01) 100%) repeat scroll 0 0 #004A97;
        border: 1px solid #004A97;
        border-bottom:none;
        color: #FFFFFF;
        display: block;
        margin-right: 1px;
        padding: 2px 8px 0;
        text-decoration: none;
        text-align: center;
        font-size:12px;

        &:hover {
          background-color: #FFFFFF;
          border-color: #FFFFFF;
          color: #5D5D5D;
        }
      }

      &.active {

        a {
          -moz-border-bottom-colors: none;
          -moz-border-left-colors: none;
          -moz-border-right-colors: none;
          -moz-border-top-colors: none;
          background-color: #FFFFFF;
          border-color: #BBBBBB #BBBBBB #FFFFFF;
          border-image: none;
          border-style: solid;
          border-width: 1px;
        }
      }
    }
  }
}



