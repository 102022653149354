/***********************************************


  inner-grid.scss
  light weight grid system based on oocss grid
  system. For use in places where a grid system
  is required but but the extra styling is not!


************************************************/

.flex-row {
  display: flex;
}

.inner-row {
  overflow: visible;
  clear: both;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;

  &:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.dynamic-col {
  overflow: visible;
  *zoom:1;
  clear: both;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;

  &:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.flex-col {
  flex: 1;
}

.inner-col {overflow:visible;}
.size1of1{;width:100%;}
.size1of2{width:50%;}
.size1of3{width:33.33333%;}
.size2of3{width:66.66666%;}
.size1of4{width:25%;}
.size3of4{width:75%;}
.size1of5{width:20%;}
.size2of5{width:40%;}
.size3of5{width:60%;}
.size4of5{width:80%;}
.size1of8{width:12.5%;}
.size3of8{width:37.5%;}
.size5of8{width:62.5%;}
.size7of8{width:87.5%;}
.size12of12{width:100%;}
.size11of12{width:91.66666667%;}
.size10of12{width:83.33333333%;}
.size9of12{width:75%;}
.size8of12{width:66.66666667%;}
.size7of12{width:58.33333333%;}
.size6of12{width:50%;}
.size5of12{width:41.66666667%;}
.size4of12{width:33.33333333%;}
.size3of12{width:25%;}
.size2of12{width:16.66666667%;}
.size1of12{width:8.33333333%;}

.offset-L-1-col{margin-left:8.33333333%;}
.offset-R-1-col{margin-right:8.33333333%;}
.offset-L-2-col{margin-left:16.66666667%;}
.offset-R-2-col{margin-right:16.66666667%;}

