/****************************************
*
* Main stylesheet
*
* Property declaration order
*
* position
* display
* box model
* color
* text
* other
*
*****************************************/

//import bootstrap
@import "bootstrap-styles";

@import "variables";
@import "palette";
@import "mixins";
@import "font-awesome";
@import "typeography";


@import "admin";
@import "base";
@import "components";


@import "global";

/* Media queries */
@import "media";



