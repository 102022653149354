$baseFontSize:  10px;

$pathToImages:          '../assets/images';
$pathToFonts:           '../assets/fonts';
$pathToComponents:      '../assets/components';
$pathToBootstrap:       '../assets/components/bootstrap-sass';
$pathToFlexslider:      '../assets/components/flexslider-scss';
$flex-slider-font-path: '../assets/components/flexslider-scss/fonts' !default;
$fa-font-path:          '../assets/components/font-awesome/fonts';
$fa-font-path:          '../assets/components/font-awesome/fonts';

