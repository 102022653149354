/******************************************************************************************
*
*
*
*   Styles for iPad pro portrait
*
*
*
*******************************************************************************************/


.fixed-width-content {
  width:100%;
  padding: 0 15px;
  margin: 0 auto;
}

.intro-section {

  .intro-section-content {
    grid-template-rows: auto;
  }
}

.the-process {

  .the-process-content {

    .the-process-grid {
      grid-template-columns: 100%;
      grid-template-rows: 350px 71px 350px 71px 350px 71px 425px 71px 425px 71px 425px 71px 425px 71px 425px;

      & > div {
        color: $brandBlue!important;
        background-color: $white!important;

        & > .step-text {
          display: block!important;
          opacity: 1!important;
          height: auto!important;
          padding: 0 12px!important;
        }

        & > .step-img {
          display: none!important;
        }

        &.pre-reveiw {
          color: $brandRed!important;
        }

        &:hover {

          h3 {
            color: $brandBlue!important;
          }

          &.pre-reveiw {

            h3 {
              color: $brandRed!important;
            }
          }
        }
      }

      //

      .step1 {
        grid-column: 1/2;
        grid-row: 1/2;
      }

      .ar-1-2 {
        grid-column: 1/2;
        grid-row: 2/3;

        span {
          width:49px!important;
          height:  28px!important;
          background: transparent url("#{$pathToImages}/red-arrow-down-rc.png") 0 0 no-repeat!important;
          background-size: 49px 28px!important;
        }
      }


      .ar-2-3 {
        grid-column: 1/2;
        grid-row: 4/5;

        span {
          width:49px!important;
          height:  28px!important;
          background: transparent url("#{$pathToImages}/red-arrow-down-rc.png") 0 0 no-repeat!important;
          background-size: 49px 28px!important;
        }
      }

      .step2 {
        grid-column: 1/2;
        grid-row: 3/4;
      }

      .ar-3-3 {
        grid-column: 1/2;
        grid-row: 4/5;
      }

      .step3 {
        grid-column: 1/2;
        grid-row: 5/6;
      }

      .ar-3-4 {
        grid-column: 1/2;
        grid-row: 6/7;
      }

      .step4 {
        grid-column: 1/2;
        grid-row: 7/8;
      }

      .ar-4-5 {
        grid-column: 1/2;
        grid-row: 8/9;

        span {
          width:49px!important;
          height:  28px!important;
          background: transparent url("#{$pathToImages}/blue-arrow-down-rc.png") 0 0 no-repeat!important;
          background-size: 49px 28px!important;
        }
      }

      .step5 {
        grid-column: 1/2;
        grid-row: 9/10;
      }

      .ar-5-6 {
        grid-column: 1/2;
        grid-row: 10/11;
      }

      .step6 {
        grid-column: 1/2;
        grid-row: 11/12;
      }

      .ar-6-7 {
        grid-column: 1/2;
        grid-row: 12/13;
      }

      .step7 {
        grid-column: 1/2;
        grid-row: 13/14;
      }

      .ar-7-8 {
        grid-column: 1/2;
        grid-row: 14/15;

        span {
          width:49px!important;
          height:  28px!important;
          background: transparent url("#{$pathToImages}/blue-arrow-down-rc.png") 0 0 no-repeat!important;
          background-size: 49px 28px!important;
        }
      }

      .step8 {
        grid-column: 1/2;
        grid-row: 15/16;
      }

      //

    }
  }
}